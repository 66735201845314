// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as ProductTile from "../../product-index/product-tile/ProductTile.res.js";
import ReactSlick from "react-slick";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationProviderProductsSliderScss from "./LocationProviderProductsSlider.scss";

var css = LocationProviderProductsSliderScss;

function bpSize(bpType) {
  if (bpType === "Desktop") {
    return 1440;
  } else if (bpType === "Tablet") {
    return 1024;
  } else {
    return 979;
  }
}

function reducer(state, action) {
  if (typeof action !== "object") {
    return {
            isClient: true,
            viewportWidth: state.viewportWidth,
            sliderStyle: state.sliderStyle
          };
  } else if (action.TAG === "MeasureViewport") {
    return {
            isClient: state.isClient,
            viewportWidth: action._0,
            sliderStyle: state.sliderStyle
          };
  } else {
    return {
            isClient: state.isClient,
            viewportWidth: state.viewportWidth,
            sliderStyle: action._0
          };
  }
}

var initialState = {
  isClient: false,
  viewportWidth: 0,
  sliderStyle: "Shown"
};

function sliderConstraintsForViewport(viewportWidth) {
  if (viewportWidth >= bpSize("Desktop")) {
    return {
            margin: 130,
            slideWidth: 280
          };
  } else if (viewportWidth < bpSize("Desktop") && viewportWidth > bpSize("Phone")) {
    return {
            margin: 60,
            slideWidth: 280
          };
  } else {
    return {
            margin: 0,
            slideWidth: 275
          };
  }
}

var Helpers = {
  spacingBetweenSliders: 20,
  sliderConstraintsForViewport: sliderConstraintsForViewport
};

function LocationProviderProductsSlider(props) {
  var mobile = props.mobile;
  var toggleCart = props.toggleCart;
  var setCartQty = props.setCartQty;
  var setUserData = props.setUserData;
  var userLoginStatus = props.userLoginStatus;
  var products = props.products;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch("SetIsClient");
          dispatch({
                TAG: "MeasureViewport",
                _0: window.innerWidth
              });
        }), []);
  var sliderConstraints = React.useMemo((function () {
          return sliderConstraintsForViewport(state.viewportWidth);
        }), [state.viewportWidth]);
  var slidesToShow = React.useMemo((function () {
          return Caml_int32.div(state.viewportWidth - (sliderConstraints.margin << 1) | 0, sliderConstraints.slideWidth + 20 | 0);
        }), [state.viewportWidth]);
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ReactSlick, {
                    dots: false,
                    infinite: true,
                    slidesToShow: slidesToShow > products.length ? products.length : (
                        slidesToShow === 0 ? 1 : slidesToShow
                      ),
                    slidesToScroll: 1,
                    className: slidesToShow >= products.length ? css.auto : "",
                    responsive: state.isClient ? [
                        {
                          breakpoint: 1440,
                          settings: {
                            slidesToShow: products.length <= 2 ? products.length : 3,
                            arrows: true,
                            centerMode: true,
                            centerPadding: "0px",
                            rows: 1,
                            slidesPerRow: 1,
                            infinite: true
                          }
                        },
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 1,
                            arrows: false,
                            centerMode: true,
                            centerPadding: "60px",
                            rows: 1,
                            slidesPerRow: 1,
                            infinite: true
                          }
                        }
                      ] : undefined,
                    rows: 1,
                    slidesPerRow: 1,
                    arrows: slidesToShow > 1,
                    children: Belt_Array.map(products, (function (product) {
                            return JsxRuntime.jsx(ProductTile.make, {
                                        product: product,
                                        className: css.product,
                                        userLoginStatus: userLoginStatus,
                                        tooltipPosition: "Top",
                                        setUserData: setUserData,
                                        setCartQty: setCartQty,
                                        toggleCart: toggleCart,
                                        mobile: mobile,
                                        setSliderStyle: (function (toggle) {
                                            dispatch({
                                                  TAG: "SetSliderStyle",
                                                  _0: toggle
                                                });
                                          })
                                      }, ID.toString(product.id));
                          }))
                  }, state.isClient ? "location-product-slider-client" : "location-product-slider-server"),
              className: Cx.cx([
                    css.sliderContainer,
                    state.sliderStyle === "Hidden" ? css.slickSliderOverride : ""
                  ])
            });
}

var make = LocationProviderProductsSlider;

var $$default = LocationProviderProductsSlider;

export {
  css ,
  bpSize ,
  reducer ,
  initialState ,
  Helpers ,
  make ,
  $$default as default,
}
/* css Not a pure module */
